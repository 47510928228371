.offcanvas-backdrop {
  width: 100%;
  height: 100%;
}

.cu-list-icon {
  height: 60px;
  width: 60px;
  font-size: 50px;
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 1000; /* Ensures it stays on top of other content */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background-color: rgba(255, 255, 255, 0.70);
  border-radius: 10px;
}

.cu-sidebar-close-button {
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(1.0 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto !important;
}

.cu-avocado-size {
  height: 60px;
  width: 60px;
}

.cu-checkbox-list {
  margin: 20px;
}

.cu-checkbox {
  margin: 10px 10px 10px 0px;
  margin-bottom: 10px !important;
  display: flex !important;
  align-items: center !important; /* Center content vertically */
  justify-content: center !important; /* Center content horizontally */
}

.cu-checkbox-input {
  margin: 10px;
  min-width: 5px;
}

.cu-title {
  margin: 20px;
}

.cu-comapny-name {
  margin: 5px;
}

.cu-store-location {
  width: 100%;
}

.cu-checkbox-container {
  width: 5px;
}

.cu-set-postal-button {
  width: 130px;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}