.cu-toast {
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 0.375rem;
}

.cu-toast-header {
  background-color: rgba(255, 255, 255, 1) !important;
}

.cu-toast-body {
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 0.375rem;
}

.cu-notification-img {
  width: 20px;
  height: 20px;
}