.App {
  min-width: 890px;
  text-align: center;
  margin-top: 60px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.results {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: start;
  justify-content: center;
} 

.cu-logo-size {
  width: 300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0px rgba(0, 255, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 255, 0, 1);
  }
  100% {
    box-shadow: 0 0 0px rgba(0, 255, 0, 0.5);
  }
}

.glow {
  animation: glow 2s infinite;
}
