.cu-item {
  min-height: 465px;
  margin: 10px;
}

.cu-image-frame {
  width: 100%;
  height: 246px;
  margin-top: 10px;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.cu-image-size {
  max-width: 246px;
  max-height: 246px;
  margin: 10px;
}

.cu-store-name {
  font-size: 32px;
}

.cu-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 266px;
}

.cu-image-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  position: absolute;
  top: 16px;
  left: 16px;
  border-radius: 5px;
  border: 2px;
  padding: 0px 8px 0px 8px;
  background-color: rgba(0, 0, 0, 0.7);
}

.cu-image-package-icon {
  font-size: 16px;
  color: 	rgb(193, 154, 107);
  padding-right: 5px;
}

.cu-button {
  margin: 5px;
  width: 100px;
}