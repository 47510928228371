html, body {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cu-content-body {
  flex: 1;  /* This makes the content area grow and occupy any extra space */
}